import { useReducer } from 'react';
import { Trade, SignaturePrintStatus, TradeDirection } from 'utils/shared/trades/types';
import { EQUITY_FILTER, State, SliderDirection } from 'utils/shared/trades/presets';
import { getIsGoldenDarkPool } from 'utils/shared/trades/getIsGoldenDarkPool';
import { TickerAggregatedDetails } from 'components/OptionAggregatedTable/props';
import getSignaturePrintStatus from 'utils/shared/trades/getSignaturePrintStatus';
import { LARGE_TRADE_MINIMUM } from 'constants/darkpool';
import { DarkPoolTrade } from 'components/DarkPoolTable/props';
import { OrderType } from 'utils/shared/trades/types';
import { mapCondensedSectors } from 'utils/shared/trades/sectors';
export const DEFAULT_COMMAND_CENTER_STATE = EQUITY_FILTER;

export const expiryBuckets = {
  ultrashort: (trade: Trade) => {
    return trade.daysToExpiry <= 7;
  },
  short: (trade: Trade) => {
    return trade.daysToExpiry > 7 && trade.daysToExpiry <= 35;
  },
  medium: (trade: Trade) => {
    return trade.daysToExpiry > 35 && trade.daysToExpiry <= 90;
  },
  long: (trade: Trade) => {
    return trade.daysToExpiry > 90;
  },
};

const msToMinutes = (ms: number) => ms / 60000;

export const getFlowFilterFunction =
  (
    filterState: State,
    tradesByTickerMap: Record<string, TickerAggregatedDetails>,
    now: number = Date.now(),
  ) =>
  (trade: Trade) => {
    if (filterState.optionType === 'Calls' && trade.cp !== 'Call') {
      return false;
    }

    if (filterState.optionType === 'Puts' && trade.cp !== 'Put') {
      return false;
    }

    if (filterState.tt === 'Stocks' && trade.tickerDetails?.sector === 'ETF') {
      return false;
    }

    if (filterState.tt === 'ETFs' && trade.tickerDetails?.sector !== 'ETF') {
      return false;
    }

    if (
      Array.isArray(filterState.activeSector) &&
      filterState.activeSector.length > 0 &&
      !(filterState.activeSector.length === 1 && filterState.activeSector[0] === 'All') &&
      !filterState.activeSector.includes(mapCondensedSectors(trade.tickerDetails?.sector || ''))
    ) {
      return false;
    }

    const tickerOverview = tradesByTickerMap[trade.ticker || ''] || {};
    const putFlow = tickerOverview.putFlow || 0;
    const momentum = tickerOverview.momentum || 0;
    const { minFlow, maxFlow } = filterState;

    if (putFlow < minFlow) {
      return false;
    }

    if (putFlow > maxFlow) {
      return false;
    }

    if (trade.price > filterState.maxPrice) {
      return false;
    }

    if (trade.price < filterState.minPrice) {
      return false;
    }

    if (trade.otmPercent < filterState.minOtm) {
      return false;
    }

    if (trade.otmPercent > filterState.maxOtm) {
      return false;
    }

    if (momentum < filterState.minMomentum) {
      return false;
    }

    if (momentum > filterState.maxMomentum) {
      return false;
    }

    if ((trade.marketCap || 0) < filterState.minMarketCap) {
      return false;
    }

    if ((trade.marketCap || 0) > filterState.maxMarketCap) {
      return false;
    }

    if ((trade.size || 0) < filterState.minSize) {
      return false;
    }

    if ((trade.size || 0) > filterState.maxSize) {
      return false;
    }

    if ((trade.premium || 0) < filterState.minPremium) {
      return false;
    }

    if ((trade.premium || 0) > filterState.maxPremium) {
      return false;
    }

    if ((trade.openInterest || 0) < filterState.minOpenInterest) {
      return false;
    }

    if ((trade.openInterest || 0) > filterState.maxOpenInterest) {
      return false;
    }

    if ((trade.impliedVol || 0) < filterState.minImpVolatility / 100) {
      return false;
    }

    if ((trade.impliedVol || 0) > filterState.maxImpVolatility / 100) {
      return false;
    }

    if (trade.createdTimestamp && msToMinutes(now - trade.createdTimestamp) < filterState.minTime) {
      return false;
    }

    if (trade.createdTimestamp && msToMinutes(now - trade.createdTimestamp) > filterState.maxTime) {
      return false;
    }

    if (filterState.topPositions && (trade.size || 0) < (trade.openInterest || 0)) {
      return false;
    }

    if (filterState.expirationDirection !== SliderDirection.All) {
      if (
        trade.daysToExpiry > filterState.maxExpiration ||
        trade.daysToExpiry < filterState.minExpiration
      ) {
        return false;
      }
    }

    if (
      Array.isArray(filterState.tradeType) &&
      filterState.tradeType.length > 0 &&
      !(filterState.tradeType.length === 1 && filterState.tradeType[0] === 'All')
    ) {
      const tradeTypeChecks = {
        'Top Positions': (trade: Trade) => trade.isTopPosition,
        'Golden Sweeps': (trade: Trade) => trade.isGoldenSweep,
        Block: (trade: Trade) => trade.orderType === OrderType.Block,
        Split: (trade: Trade) => trade.orderType === OrderType.Split,
        Sweeps: (trade: Trade) => trade.orderType === OrderType.Sweep,
        'Above Ask': (trade: Trade) => trade.tradeDirection === TradeDirection.AboveAsk,
        Unusual: (trade: Trade) => trade.isUnusual,
      };

      // Define a type for the keys of tradeTypeChecks
      type TradeTypeKey = keyof typeof tradeTypeChecks;

      const matchesTradeType = filterState.tradeType.some(
        (type) =>
          tradeTypeChecks[type as TradeTypeKey] && tradeTypeChecks[type as TradeTypeKey](trade),
      );

      if (!matchesTradeType) {
        return false;
      }
    }

    const { ultrashort, short, medium, long } = filterState;
    const validationFunctions = [];
    /**
     * @todo how to handle expiration exact values and buckets?
     * UPDATE NOW TO CHANGE MIN AND MAX VALUES, and not multi-select
     */
    if (ultrashort) {
      validationFunctions.push(expiryBuckets.ultrashort);
    }
    if (short) {
      validationFunctions.push(expiryBuckets.short);
    }
    if (medium) {
      validationFunctions.push(expiryBuckets.medium);
    }
    if (long) {
      validationFunctions.push(expiryBuckets.long);
    }

    if (validationFunctions.length > 0) {
      let invalidExpiryBucket = true;
      for (let i = 0; i < validationFunctions.length; i++) {
        if (validationFunctions[i](trade)) {
          invalidExpiryBucket = false;
        }
      }
      if (invalidExpiryBucket) {
        return false;
      }
    }

    return true;
  };

export const getAggregateFilterFunction =
  (filterState: State) => (trade: TickerAggregatedDetails) => {
    const putFlow = trade.putFlow || 0;
    const momentum = trade.momentum || 0;
    const { minFlow, maxFlow } = filterState;

    if (filterState.tt === 'Stocks' && trade?.sector === 'ETF') {
      return false;
    }

    if (filterState.tt === 'ETFs' && trade?.sector !== 'ETF') {
      return false;
    }

    if (
      Array.isArray(filterState.activeSector) &&
      filterState.activeSector.length > 0 &&
      !(filterState.activeSector.length === 1 && filterState.activeSector[0] === 'All') &&
      !filterState.activeSector.includes(mapCondensedSectors(trade.sector || ''))
    ) {
      return false;
    }

    if (putFlow < minFlow) {
      return false;
    }

    if (putFlow > maxFlow) {
      return false;
    }

    if (momentum < filterState.minMomentum) {
      return false;
    }

    if (momentum > filterState.maxMomentum) {
      return false;
    }

    if ((trade.marketCap || 0) < filterState.minMarketCap) {
      return false;
    }

    if ((trade.marketCap || 0) > filterState.maxMarketCap) {
      return false;
    }

    /**
     * @note OTM could be confusing because it's an average here, it's not based on an aggregate value like the others in this function
     */
    // if ((trade.otm || 0) < filterState.minOtm) {
    //   return false;
    // }
    //
    // if ((trade.otm || 0) > filterState.maxOtm) {
    //   return false;
    // }

    /**
     * @note Make sure this works on aggregate and just needs to hit one item in the list of trades to be true
     */
    if (
      Array.isArray(filterState.tradeType) &&
      filterState.tradeType.length > 0 &&
      !(filterState.tradeType.length === 1 && filterState.tradeType[0] === 'All')
    ) {
      const tradeTypeChecks = {
        'Top Positions': (trade: TickerAggregatedDetails) =>
          trade.trades.some((trade) => trade.isTopPosition),
        'Golden Sweeps': (trade: TickerAggregatedDetails) => trade.goldenSweepContracts > 0,
        Block: (trade: TickerAggregatedDetails) => trade.blockContracts > 0,
        Split: (trade: TickerAggregatedDetails) => trade.splitContracts > 0,
        Sweeps: (trade: TickerAggregatedDetails) => trade.sweepContracts > 0,
        'Above Ask': (trade: TickerAggregatedDetails) =>
          trade.trades.some((trade) => trade.tradeDirection === TradeDirection.AboveAsk),
        Unusual: (trade: TickerAggregatedDetails) => trade.unusualContracts > 0,
      };

      // Define a type for the keys of tradeTypeChecks
      type TradeTypeKey = keyof typeof tradeTypeChecks;

      const matchesTradeType = filterState.tradeType.some(
        (type) =>
          tradeTypeChecks[type as TradeTypeKey] && tradeTypeChecks[type as TradeTypeKey](trade),
      );

      if (!matchesTradeType) {
        return false;
      }
    }

    return true;
  };

export const getDarkPoolFilterFunction = (filterState: State) => (trade: DarkPoolTrade) => {
  if ((trade?.size || 0) < filterState.minSizeDp) {
    return false;
  }

  if ((trade?.size || 0) > filterState.maxSizeDp) {
    return false;
  }

  if ((trade.amount || 0) < filterState.minAmtDp) {
    return false;
  }

  if ((trade.amount || 0) > filterState.maxAmtDp) {
    return false;
  }

  if (
    Array.isArray(filterState.activeSector) &&
    filterState.activeSector.length > 0 &&
    !(filterState.activeSector.length === 1 && filterState.activeSector[0] === 'All') &&
    !filterState.activeSector.includes(mapCondensedSectors(trade.tickerDetails?.sector || ''))
  ) {
    return false;
  }

  if (
    Array.isArray(filterState.typedp) &&
    filterState.typedp.length > 0 &&
    !(filterState.typedp.length === 1 && filterState.typedp[0] === 'All')
  ) {
    const signaturePrintStatus = getSignaturePrintStatus(trade, { open: trade.open });
    const typedpChecks = {
      'Late Buys': () => signaturePrintStatus === SignaturePrintStatus.Buy,
      'Late Sells': () => signaturePrintStatus === SignaturePrintStatus.Sell,
      'High Volume': () => getIsGoldenDarkPool(trade.size, trade.avgVolume),
      'Large Premium': () => trade.amount > LARGE_TRADE_MINIMUM,
    };

    // Define a type for the keys of typedpChecks
    type TypedpKey = keyof typeof typedpChecks;

    const matchesTypedp = filterState.typedp.some(
      (type) => typedpChecks[type as TypedpKey] && typedpChecks[type as TypedpKey](),
    );

    if (!matchesTypedp) {
      return false;
    }
  }

  return true;
};

enum ActionTypes {
  Set,
  Reset,
}

interface Action {
  type?: ActionTypes;
  payload?: {
    items: Partial<State>;
  };
}

const getNumberOfFilters = (state: State): number => {
  let filters = 0;

  Object.keys(DEFAULT_COMMAND_CENTER_STATE).forEach((key) => {
    if (key === 'isPreciseFiltersEnabled') {
      return;
    }

    const typedKey = key as keyof State;
    const defaultValue = DEFAULT_COMMAND_CENTER_STATE[typedKey];
    const currentStateValue = state[typedKey];

    if (typedKey.toLowerCase().includes('direction')) {
      return;
    }

    if (typedKey === 'tradeType' || typedKey === 'typedp' || typedKey === 'activeSector') {
      // Handle tradeType as an array with default ['All']
      if (
        Array.isArray(currentStateValue) &&
        currentStateValue.length === 1 &&
        currentStateValue[0] === 'All'
      ) {
        return; // Skip incrementing filters if tradeType is ['All']
      }
    }

    if (defaultValue !== currentStateValue) {
      filters++;
    }
  });

  return filters;
};

const reducer: React.Reducer<State, Action> = (state, { type, payload }) => {
  switch (type) {
    case ActionTypes.Set: {
      if (payload && payload.items) {
        return {
          ...state,
          ...payload.items,
        };
      }
    }
    case ActionTypes.Reset:
      return { ...DEFAULT_COMMAND_CENTER_STATE };
    default:
      return state;
  }
};

export const useCommandCenter = () => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_COMMAND_CENTER_STATE);
  return {
    state,
    dispatch,
    numberOfFilters: getNumberOfFilters(state),
    ActionTypes,
  };
};
