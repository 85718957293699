export const ETF_SECTOR = 'ETF';

const sectorMap: Record<string, string> = {
  // Technology mappings
  Technology: 'Technology',
  Tech: 'Technology',

  // Healthcare mappings
  'Health Care': 'Healthcare',
  Healthcare: 'Healthcare',

  // Financial Services mappings
  Finance: 'Financial',
  'Financial Services': 'Financial',
  Financials: 'Financial',

  // Consumer Cyclical mappings
  'Consumer Cyclical': 'Discretionary',
  'Consumer Durables': 'Discretionary',
  'Consumer Non-Durables': 'Discretionary',
  'Consumer Services': 'Discretionary',
  Discretionary: 'Discretionary',

  // Consumer Defensive mappings
  'Consumer Defensive': 'Staples',
  Staples: 'Staples',

  // Industrials mappings
  Industrials: 'Industrials',
  Transportation: 'Industrials',
  'Capital Goods': 'Industrials',

  // Energy mappings
  Energy: 'Energy',
  'Public Utilities': 'Utilities', // Note: Changed to map to Utilities instead of Energy

  // Basic Materials mappings
  'Basic Materials': 'Materials',
  'Basic Industries': 'Materials',
  Materials: 'Materials',

  // Direct mappings (no variations)
  'Communication Services': 'Communications',
  'Real Estate': 'Real Estate',
  Utilities: 'Utilities',
  ETF: ETF_SECTOR,

  // Clean up any legacy mappings
  Miscellaneous: 'Miscellaneous', // You may want to decide how to handle this
};

export const mapCondensedSectors = (originalSector: string) =>
  sectorMap[originalSector] || originalSector;

const SECTOR_SHORT_NAMES: Record<string, string> = {
  Technology: 'Tech',
  Healthcare: 'Hlth',
  Financial: 'Fin',
  Discretionary: 'Disc',
  Staples: 'Stpl',
  Industrials: 'Indst',
  Energy: 'Engy',
  Materials: 'Mat',
  Communications: 'Comm',
  'Real Estate': 'RE',
  Utilities: 'Util',
  [ETF_SECTOR]: 'ETF',
  Miscellaneous: 'Misc',
  '': '-',
};

export const getSectorShortName = (sector: string) => {
  const mappedSector = mapCondensedSectors(sector);
  return SECTOR_SHORT_NAMES[mappedSector] || mappedSector;
};
