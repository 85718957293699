import { MIN_VALUE, MAX_VALUE, NEGATIVE_MIN_VALUE } from 'utils/shared/trades/presets';

export const TRADE_TYPE = [
  { label: 'All', value: 'All', color: undefined },
  { label: 'Top Positions', value: 'topPositions', color: undefined },
  { label: 'Golden Sweeps', value: 'goldenSweep', color: '#fbbf24' },
  { label: 'Block', value: 'block', color: undefined },
  { label: 'Split', value: 'split', color: undefined },
  { label: 'Above Ask', value: 'aa', color: undefined },
  { label: 'Unusual', value: 'unusual', color: '#3c8df7' },
  { label: 'Sweeps', value: 'sweeps', color: '#b64cc7' },
];

export const OPTION_TYPE = [
  { label: 'All', value: 'All', color: undefined },
  { label: 'Calls', value: 'calls', color: '#17be64' },
  { label: 'Puts', value: 'puts', color: '#f04662' },
];

export const PRICE_OPTIONS = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: '< $0.50', min: MIN_VALUE, max: 0.5 },
  { label: '< $1', min: MIN_VALUE, max: 1 },
  { label: '< $3', min: MIN_VALUE, max: 3 },
  { label: '> $3', min: 3, max: MAX_VALUE },
  { label: '> $10', min: 10, max: MAX_VALUE },
  { label: '> $20', min: 20, max: MAX_VALUE },
];

export const TICKER_TYPE_OPTIONS = [
  { label: 'Stocks', value: 'stocks' },
  { label: 'ETFs', value: 'etfs' },
  { label: 'All', value: 'All' },
];

export const EXP_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Ultra Short', value: 'ultrashort' },
  { label: 'Short', value: 'short' },
  { label: 'Medium', value: 'medium' },
  { label: 'Long', value: 'long' },
];

export const OTM_OPTIONS = [
  { label: 'All', min: NEGATIVE_MIN_VALUE, max: MAX_VALUE },
  { label: '>0%', min: 1, max: MAX_VALUE },
  { label: '>5%', min: 5, max: MAX_VALUE },
  { label: '>10%', min: 10, max: MAX_VALUE },
  { label: '>20%', min: 20, max: MAX_VALUE },
];

export const MOMENTUM_OPTIONS = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: '>1x', min: 1, max: MAX_VALUE },
  { label: '>2x', min: 2, max: MAX_VALUE },
  { label: '>5x', min: 5, max: MAX_VALUE },
  { label: '>10x', min: 10, max: MAX_VALUE },
];

export const TICKER_FLOW_OPTIONS = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: '0%', min: MIN_VALUE, max: 0 },
  { label: '< 5%', min: MIN_VALUE, max: 5 },
  { label: '< 20%', min: MIN_VALUE, max: 20 },
  { label: '> 80%', min: 80, max: MAX_VALUE },
  { label: '> 95%', min: 95, max: MAX_VALUE },
  { label: '100%', min: 100, max: MAX_VALUE },
];

export const SIZE_OPTIONS = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: '<500', min: MIN_VALUE, max: 500 },
  { label: '<1K', min: MIN_VALUE, max: 1000 },
  { label: '>500', min: 500, max: MAX_VALUE },
  { label: '>1K', min: 1000, max: MAX_VALUE },
  { label: '>5K', min: 5000, max: MAX_VALUE },
];

export const PREMIUM_OPTIONS = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: '<$20K', min: MIN_VALUE, max: 20000 },
  { label: '<$100K', min: MIN_VALUE, max: 100000 },
  { label: '>$100K', min: 100000, max: MAX_VALUE },
  { label: '>$200K', min: 200000, max: MAX_VALUE },
  { label: '>$1M', min: 1000000, max: MAX_VALUE },
];

export const VOLATILITY_OPTIONS = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: '<25%', min: MIN_VALUE, max: 25 },
  { label: '<50%', min: MIN_VALUE, max: 50 },
  { label: '>50%', min: 50, max: MAX_VALUE },
  { label: '>100%', min: 100, max: MAX_VALUE },
];

export const OPEN_INTEREST_OPTIONS = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: '<1K', min: MIN_VALUE, max: 1000 },
  { label: '<5K', min: MIN_VALUE, max: 5000 },
  { label: '>25K', min: 25000, max: MAX_VALUE },
  { label: '>75K', min: 75000, max: MAX_VALUE },
];

export const MARKET_CAP_OPTIONS = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: '<$300M', min: MIN_VALUE, max: 300000000 },
  { label: '$300M - $2B', min: 300000000, max: 2000000000 },
  { label: '$2B - $10B', min: 2000000000, max: 10000000000 },
  { label: '>$200B', min: 200000000000, max: MAX_VALUE },
];

export const SECTOR_OPTIONS = [
  { label: 'All' },
  { label: 'Technology' },
  { label: 'Healthcare' },
  { label: 'Financial' },
  { label: 'Discretionary' },
  { label: 'Industrials' },
  { label: 'Staples' },
  { label: 'Energy' },
  { label: 'Materials' },
  { label: 'Communications' },
  { label: 'Real Estate' },
  { label: 'Utilities' },
  // { label: 'ETF' },
];

export const TIME_OPTIONS = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: 'Last 15 minutes', min: MIN_VALUE, max: 15 },
  { label: 'Last Hour', min: MIN_VALUE, max: 60 },
  { label: 'Last 4 Hours', min: MIN_VALUE, max: 240 },
];

export const SIZE_OPTIONS_DP = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: '<250K', min: MIN_VALUE, max: 250000 },
  { label: '>250K', min: 250000, max: MAX_VALUE },
  { label: '>1M', min: 1000000, max: MAX_VALUE },
];

export const TYPE_OPTIONS_DP = [
  { label: 'All' },
  { label: 'Late Buys' },
  { label: 'Late Sells' },
  { label: 'High Volume' },
  { label: 'Large Premium' },
];

export const AMOUNT_OPTIONS_DP = [
  { label: 'All', min: MIN_VALUE, max: MAX_VALUE },
  { label: '<$5M', min: MIN_VALUE, max: 5_000_000 },
  { label: '>$10M', min: 10_000_000, max: MAX_VALUE },
  { label: '>$25M', min: 25_000_000, max: MAX_VALUE },
  { label: '>$50M', min: 50_000_000, max: MAX_VALUE },
];
